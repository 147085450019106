import { EVComparisonLandingTemplate } from '@/components/template/EVComparisonLandingTemplate/EVComparisonLandingTemplate';
import { getPopularEVCompare } from '@/lib/getPopularEVCompare';
import { SEOMetaTags } from '@/lib/seo/seoTags';
import { EVDetailProps } from '@/types/compare-ev';
import { BasePageProps } from '@/types/page';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

export interface EVComparisonProps {
  makes: string[];
  popularEVCompare: EVDetailProps[][];
}

const EVComparison: React.FC<EVComparisonProps> = ({
  makes,
  popularEVCompare,
}) => {
  return (
    <div className="w-full">
      <EVComparisonLandingTemplate
        makes={makes}
        selectedModelsCompare={popularEVCompare}
      />
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  let makes = [];
  const makesResponse = await fetch(
    `${process.env.API_ENDPOINT}/api/build/makes`
  );
  if (makesResponse.ok) {
    const makesJson = await makesResponse.json();
    makes = makesJson.Makes;
  }
  const fetchedEVCompare = await getPopularEVCompare();
  const popularEVCompare = fetchedEVCompare.map((subArray: EVDetailProps[][]) =>
    subArray.map((obj) =>
      Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key.charAt(0).toLowerCase() + key.slice(1),
          value,
        ])
      )
    )
  );

  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common'])),
      makes,
      popularEVCompare,
      metadata: {
        title: SEOMetaTags.comparePage.title,
        description: SEOMetaTags.comparePage.description,
      },
    } satisfies BasePageProps<EVComparisonProps>,
  };
};

export default EVComparison;
