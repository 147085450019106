import { PRICE_COMING_SOON } from './constants';

export const priceFormatter = (
  msrp?: number,
  fallbackText?: string
): string => {
  const price = msrp || 0;
  return price === 0
    ? (fallbackText ?? PRICE_COMING_SOON)
    : `$${Math.round(price).toLocaleString()}`;
};
